import React from 'react'

import './about.scss'

import PicAbout from '../images/about'

export default () =>
  <section id="about" className="container px-0 About">
    <PicAbout style={{
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      //height: "100%",
      zIndex: 1,
    }} />

    <div className="About-content my-0">

      <div className="container">
          <div className="row align-items-center">
            <div className="col-12 offset-0 col-md-6 offset-md-6">
              <div className="About-cta p-4 my-2">
                <h2>About the creator</h2>
                <h3>Rina Botha</h3>
                <p>The desire to create a story book has always lingered within me. In 2013 I started the marvellous
                journey with Jock. It was an exciting and sometimes daunting project. But a journey that I am
                grateful for, as it was a big milestone for the artist within me.</p>

                <p>I wanted to tell a simple <mark>tale of adventure and friendship</mark>, set in the <mark>beautiful rolling New Zealand
                landscape</mark>. It was important to be me to strive to capture the essence of New Zealand and not conform to
                generalisation of storytelling.</p>
              </div>
            </div>
          </div>
      </div>

    </div>
  </section>
