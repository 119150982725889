import React from 'react'
import { formatCurrency } from '../../../helpers'
import Summary from './summary'

const BuyForm = ({
  working,
  visible,
  copies,
  freight_key,

  book,
  quantities,
  freight,

  onCopiesChanged,
  onDestinationChanged,
  onBuyClick,
}) => {
  const destination = freight_key && freight.destinations.find(
    x => x.key === freight_key
  )

  return <div className={"Checkout mt-4" + (!visible && "d-none")}>
    <form className="form form-horizontal">
      <div className="form-group">
        <label className="d-block">Copies</label>
        {quantities.map(x =>
          <div className="custom-control custom-radio custom-control-inline" key={x}>
            <input type="radio" id={`copies_${x}`} name="copies" value={x} className="custom-control-input" onChange={onCopiesChanged} checked={x === copies} disabled={working} />
            <label className="custom-control-label" htmlFor={`copies_${x}`}>
              <small>&times;</small><big>{x}</big>
            </label>
          </div>
        )}
      </div>
      <div className="form-group">
        <label className="d-block">Delivery to</label>
        {freight.destinations.map((x, i) =>
          <div className="custom-control custom-radio" key={x.key}>
            <input type="radio" id={`destination_${i}`} name="freight_key" value={x.key} className="custom-control-input" onChange={onDestinationChanged} checked={x.key === freight_key} disabled={working} />
            <label className="custom-control-label" htmlFor={`destination_${i}`}>
              {x.label} <small className="text-warning">(+${formatCurrency(Math.min((copies || 1) * x.fee, x.maxFee || 9999))})</small>
            </label>
          </div>
        )}
      </div>
      <Summary
        working={working}
        book={book}
        copies={copies}
        destination={destination}
        onBuyClick={onBuyClick}
      />
      <div className="row">
          <div className="col">
            <hr />
            <h5>Pickups?</h5>
            <p>We also accept pick-ups if you're near Torbay in Auckland and wish to pay cash or via online banking.
            Please contact me if you'd like to arrange this.</p>
          </div>
      </div>
    </form>
  </div>
}

export default BuyForm
