import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import Helmet from 'react-helmet'

import Billboard from '../components/home/billboard'
import About from '../components/home/about'
import SneakPeek from '../components/home/sneak-peek'
import Details from '../components/home/details'
import Journey from '../components/home/journey'

import book from '../data/books/9780473460327'

//import SampleSection from '../components/home/sample-section'

const query = graphql`
  query {
    site {
      siteMetadata {
        baseUri
      }
    }
    cardImage: file(relativePath: { eq: "books-1000x1000.png" }) {
      childImageSharp {
        fixed(width: 600, quality: 100) {
          src
          width
          height,
          srcSet
          originalName
        }
      }
    }
  }
`

const Meta = () =>
  <StaticQuery
    query={query}
    render={data =>
      <Helmet
        title={`Rina Botha's new children's book, ${book.title}`}
        link={[
          { rel: "canonical", href: data.site.siteMetadata.baseUri + "/" },
        ]}
        meta={[
          { name: "description", content: book.blurb },
          { name: "keywords", content: "Rina Botha, Adventure Jock and the treasure map, Children's Book, Art, Painting, New Zealand" },

          { property: "og:type", content: "book" },
          { property: "og:title", content: book.title },
          { property: "og:description", content: book.blurb },
          { property: "og:url", content: data.site.siteMetadata.baseUri + "/" },
          { property: "og:locale", content: "en_NZ" },

          { property: "og:image", content: data.site.siteMetadata.baseUri + data.cardImage.childImageSharp.fixed.src },
          { property: "og:image:type", content: "image/png" },
          { property: "og:image:width", content: data.cardImage.childImageSharp.fixed.width },
          { property: "og:image:height", content: data.cardImage.childImageSharp.fixed.height },
          { property: "og:image:alt", content: `A picture of the cover for ${book.title}` },

          { name: "twitter:card", content: "summary" },
          { name: "twitter:site", content: "@rinabotha" },

          { name: "book:isbn", content: book.isbn },
          { name: "book:author", content: book.authorName },
          { name: "book:release_date", content: book.releaseDate },
        ]}
      />
    }
  />

const IndexPage = () =>
  <Layout>
    <Meta />
    <Billboard />
    <About />
    <SneakPeek />
    <Journey />
    <Details />
  </Layout>

export default IndexPage
