import React, { Component, Fragment } from 'react'
import uuid from 'uuid/v4'
import { withinRange, convertWholeDollarsToCents, round, formatCurrency } from '../../../helpers'
import book from '../../../data/books/9780473460327'
import freight from '../../../data/freight'

import BuyForm from './buy-form'
import Thanks from './thanks'

const MAX_QTY = 5
const QUANTITIES = Array.from(Array(MAX_QTY).keys()).map(x => x + 1)

let stripeHandler = undefined

class Checkout extends Component {
  state = {
    working: false,
    show: false,

    copies: 1,
    freight_key: freight.defaultDestinationKey,

    error: undefined,
    charge: undefined,
  }

  constructor () {
    super()

    this.openStripeCheckout = this.openStripeCheckout.bind(this)
    this.handleToggleClick = this.handleToggleClick.bind(this)
    this.handleCopiesChange = this.handleCopiesChange.bind(this)
    this.handleDestinationChange = this.handleDestinationChange.bind(this)
    this.handleThankyouClick = this.handleThankyouClick.bind(this)
  }

  componentDidMount() {
    stripeHandler = window.StripeCheckout.configure({
      key: process.env.GATSBY_STRIPE_PUBLISHABLE_KEY,
      locale: "auto",

      // opened: () => {
      // },

      // closed: () => {
      // }
    })

    window.addEventListener("popstate", () => {
      stripeHandler.close()
    })
  }

  openStripeCheckout(event) {
    event.preventDefault()

    const { copies, freight_key } = this.state
    const destination = freight_key && freight.destinations.find(
      x => x.key === freight_key
    )

    const description = `${copies} x ${book.title}`
    const totalAmount = round((copies * book.price.amount) + Math.min(copies * destination.fee, destination.maxFee || 9999), 2)

    stripeHandler.open({
      name: "Rina Botha",
      image: this.props.imageUri,
      description,
      zipCode: true,
      shippingAddress: true,
      billingAddress: true,
      amount: convertWholeDollarsToCents(totalAmount),
      currency: "NZD",

      token: (token, args) => {
        this.setState({
          working: true,
        })

        const payload = {
          idempotency_key: uuid(),
          token,
          args,
          copies,
          freight_key,
          description,
        }

        fetch("/.netlify/functions/purchase", {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
          body: JSON.stringify(payload),
        })

        .then(response => response.json())

        .then(json => {
          let error = undefined
          if (json.status !== "succeeded") {
            error = {
              message: json.message || "Transaction failed."
            }
          }

          this.setState({
            working: false,
            copies: 1,
            freight_key: "",
            error,
            charge: json.charge,
          })
        })

        .catch(error => {
          console.error("Fetch failed:", error)
          this.setState({
            working: false,
            error: {
              message: `Transaction failed: ${error}`
            },
            charge: undefined,
          })
        })

      }
    })
  }

  handleToggleClick() {
    this.setState(state => ({
      show: !state.show,
    }))
  }

  handleThankyouClick() {
    this.setState(state => ({
      show: false,
      charge: undefined,
    }))
  }

  handleCopiesChange(event, valueAsNumber) {
    if (typeof valueAsNumber !== "number") {
      const { target: { value, min, max } } = event

      // NB: native valueAsNumber doesn't work in IE
      valueAsNumber = Number(value)

      const shouldUpdate = value && withinRange(min, max, valueAsNumber)
      if (!shouldUpdate) {
        return
      }
    }

    this.setState({ copies: valueAsNumber })
  }

  handleDestinationChange(event) {
    const { target: { value } } = event
    this.setState({ freight_key: value })
  }

  render () {

    const { show, error, charge } = this.state

    if (!error && charge) {
      return <Thanks
        book={book}
        charge={charge}
        onCloseClick={this.handleThankyouClick}
      />
    }

    return <Fragment>
      <h4 className="Billboard-buyNow text-uppercase">
        Buy Now for ${formatCurrency(book.price.amount)}*
      </h4>
      <p className="small">
        * Pricing is in New Zealand Dollars and excludes delivery.
      </p>

      { error &&
          <div className="alert alert-danger">
            {error.message}
          </div>
      }

      { !show ? (

        <button className="btn btn-buynow font-weight-bold text-uppercase" onClick={this.handleToggleClick}>
          Buy Adventure Jock
        </button>

      ) : (

        <BuyForm
          working={this.state.working}
          visible={show}
          copies={this.state.copies}
          freight_key={this.state.freight_key}

          book={book}
          freight={freight}
          quantities={QUANTITIES}

          onCopiesChanged={this.handleCopiesChange}
          onDestinationChanged={this.handleDestinationChange}
          onBuyClick={this.openStripeCheckout}
        />

      )}
    </Fragment>
  }
}

export default Checkout
