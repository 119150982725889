import snail from '../../images/sneakpeek/snail'
import beach from '../../images/sneakpeek/beach'
import bush from '../../images/sneakpeek/bush'
import hills from '../../images/sneakpeek/hills'
import map from '../../images/sneakpeek/map'
import mud from '../../images/sneakpeek/mud'
import pipi from '../../images/sneakpeek/pipi'


export const panels = [
  {
    key: "snail",
    name: "A slimy snail",
    caption: "blocking the path",
    icon: "fa-hand-paper",
    Image: snail,
  },

  {
    key: "beach",
    name: "Tricky walking",
    caption: "on a white sandy beach",
    icon: "fa-walking",
    Image: beach,
  },

  {
    key: "bush",
    name: "A fluttering fantail",
    caption: "in a beautiful bush",
    icon: "fa-leaf",
    Image: bush,
  },

  {
    key: "hills",
    name: "Jock’s house",
    caption: "in a rolling New Zealand landscape",
    icon: "fa-hiking",
    Image: hills,
  },

  {
    key: "map",
    name: "A treasure map",
    caption: "found next to a ponga",
    icon: "fa-map",
    Image: map,
  },

  {
    key: "mud",
    name: "Squish-squelch, squish-squelch",
    caption: "walking in the mud",
    icon: "fa-shoe-prints",
    Image: mud,
  },

  {
    key: "pipi",
    name: "A bunch of pipi",
    caption: "piping in the sand",
    icon: "fa-umbrella-beach",
    Image: pipi,
  },

]
