import React from 'react'
import Timeline from './timeline'
import Entry from './entry'

import './index.scss'

import ColoursPic from '../../images/journey/colours'
import DrawingPic from '../../images/journey/drawing'
import EditorsPic from '../../images/journey/editors'
import FacebookPic from '../../images/journey/facebook'
import LiamModelPic from '../../images/journey/liam-model'
import ModelPic from '../../images/journey/model'
import ProofsPic from '../../images/journey/proofs'
import StoryBoardPic from '../../images/journey/storyboard'

export default () =>
  <section id="thejourney" className="TheJourney p-0">
    <div className="container-fluid p-0 py-1 TheJourney-content">
      <div className="container">
        <div className="mx-2 my-5 m-md-5">

          <div className="pb-2 my-4 border-bottom">
            <h2>The Journey</h2>
          </div>

          <Timeline>
            <Entry
              title="Jock was born"
              badgeIcon="fa fa-fire"
              badgeClass=""
              time="Beginning 2013"
              inverted={false}
            >
              <p><em>The Jock</em> story was born while telling magical stories in
              the early morning hours to my son, trying to keep him quiet for just a little bit
              longer. It was this particular story that he wanted to hear over and
              over again.</p>

              <p>A few years later he modelled for me as Jock!</p>

              <LiamModelPic className="rounded my-3" alt="A boy poses as a book character" />
            </Entry>

            <Entry
              title="Workshop"
              badgeIcon="fa fa-male"
              badgeClass=""
              time="Ending 2013"
              inverted={true}
            >
              <ModelPic className="rounded float-md-right ml-md-3" alt="A clay model of the books main character" />
              <p>I participated in a children’s book illustration workshop on character development
              by Sandra Morris at the end of 2013. It was a blissful weekend spent developing
              Jock and seeing him come to life with a clay model.
              </p>
            </Entry>

            <Entry
              title="Deciding on a technique"
              badgeIcon="fa fa-lightbulb"
              badgeClass=""
              time=""
              inverted={false}
            >
              <DrawingPic className="rounded my-3" alt="A watercolour painting of a house" />
              <p>The next step was to decide what techniques I want to use to illustrate the book.
              I knew that I wanted to use my computer as little as possible. I usually work in acrylics,
              but I soon realised that watercolour would make more sense for my book illustrations.</p>
              <p>I finally decided on using watercolour and coloured pencils, with an oil pencil for the outline.</p>
            </Entry>

            <Entry
              title="Developing the storyboard"
              badgeIcon="fa fa-film"
              badgeClass=""
              time=""
              inverted={true}
            >
              <StoryBoardPic className="rounded my-3" alt="" />
              <p>Now this was a lot of fun. I love compositions! I started with small thumbnails,
              working out the overall flow between the pages. Then I worked at adding more and more
              detail, until I reached my final sketches.</p>
            </Entry>

            <Entry
              title="Keeping to the colour pallete"
              badgeIcon="fa fa-palette"
              badgeClass=""
              time=""
              inverted={false}
            >
              <ColoursPic className="rounded my-3" alt="A picture of the colours used to form the palette" />
              <p>Next I worked out what colours to use for each component of the book. It was important
              to use the same mix for sequential pages.</p>
            </Entry>

            <Entry
              title="Getting help from editors"
              badgeIcon="fa fa-pencil-alt"
              badgeClass=""
              time="During 2018"
              inverted={true}
            >
              <p>I am not a writer, so I asked Nicola McCloy, Karen de Wet and Lisa Dunlop to help
              me edit and improve the text which slowly developed into the story I'm publishing here.</p>
              <EditorsPic className="rounded mt-3" alt="Picture showing the story being edited" />
            </Entry>

            <Entry
              title="Setting the pages up for printing"
              badgeIcon="fa fa-cog"
              badgeClass=""
              time="October 2018"
              inverted={false}
            >
              <ProofsPic className="rounded my-3" alt="A spread of pages used in the book" />
              <p>Hans Weichselbaum form <a href="http://www.digital-image.co.nz">Digital Image</a> did a
              wonderful job scanning my artwork. I then used Adobe Photoshop to clean the scans up a little.
              After that, I laid out the images with Adobe inDesign and added the story's text. The
              helpful Amy from <a href="http://bookprint.co.nz/">Bookprint</a> showed me some tricks to set
              my files up correctly for the double spreads. I had to check the proofs and then the book was
              ready to go to print!</p>
            </Entry>

            <Entry
              title="Promoting and selling the book"
              badgeIcon="fa fa-broadcast-tower"
              badgeClass=""
              time="November 2018"
              inverted={true}
            >
              <p>My lovely <a href="http://singular.co.nz/#dave">husband</a> built the new website for my book.
              I asked for a static website, but he couldn't resist building it with Gatsby, React and Stripe.</p>
              <p>And now it's time to promote my book and and share it with the world!</p>
              <FacebookPic className="rounded mt-3" alt="Picture of the book being promoted on a popular social media website" />
            </Entry>

          </Timeline>

        </div>
      </div>
    </div>
  </section>
