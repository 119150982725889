import React from 'react'

import book from '../../data/books/9780473460327'

import './details.scss'

export default () =>
  <section id="details" className="container Details">
    <div className="mx-2 my-5 m-md-5">
      <div className="pb-2 my-4 border-bottom">
        <h2>Book Details</h2>
      </div>

      <div className="row">
        <div className="col-lg">
          <dl>
            <dt>Title</dt>
            <dd>{book.title}</dd>
            <dt>Author, Illustrator</dt>
            <dd>{book.authorName}</dd>
            <dt>ISBN</dt>
            <dd>{book.isbn}</dd>
            <dt>Publisher</dt>
            <dd>{book.publisherName}</dd>
            <dt>Weight</dt>
            <dd>{book.dimensions.weight}</dd>
            <dt>Dimensions</dt>
            <dd>{book.dimensions.height} &times; {book.dimensions.width} &times; {book.dimensions.depth}</dd>
            <dt>Format</dt>
            <dd>{book.format}</dd>
            <dt>Medium</dt>
            <dd>{book.medium}</dd>
          </dl>
        </div>

        <div className="col-lg-4">
          <p>The illustrations in this book were created with watercolour and coloured pencils
          (Faber Castell Polychromos) on Archers 300g hot pressed paper. For the outlines an
          oil pencil (Cretacolor Sepia Dark) was used.</p>
          <p>Printed by Bookprint in Auckland.</p>
          <p>View <a href="https://natlib.govt.nz/records/40370730">National Library</a> record.</p>

          <dl>
            <dt id="alternate-formats">Alternate Formats</dt>
            <dd><a href="https://www.amazon.com/Adventure-Jock-treasure-Rina-Botha-ebook/dp/B07PG5P2R3">Amazon Kindle</a> (ebook)</dd>
            <dt>Library Availability</dt>
            <dd><a href="https://natlib-primo.hosted.exlibrisgroup.com/permalink/f/1s57t7d/NLNZ_ALMA21317332340002836">Alexander Turnbull Library</a></dd>
          </dl>
        </div>
      </div>
    </div>
</section>
