import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Picture from 'gatsby-image'

export default props =>
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "thejourney/colours.jpg" }) {
          ...journeyImage
        }
      }
    `}
    render={data =>
      <Picture fluid={data.image.childImageSharp.fluid} {...props} />
    }
  />
