import React from 'react'

const imageStyling = {
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  //zIndex: 1,
}

const Pane = ({
  index,
  active,
  icon,
  Image,
  name,
  caption,
  onClick
}) =>
  <div
    className={"peek-panel--option" + (active ? " pane-active" :  " pane-passive")}
    //style={{
    // --peek-background": `url(${image})`,
    //}}
    onClick={e => onClick(e, index)}
  >
    <Image style={imageStyling} />
    <div className="pane-shadow"></div>
    <div className="pane-label">
      <div className="pane-icon">
        <i className={"fas " + icon}></i>
      </div>
      <div className="pane-info">
        <div className="pane-name">{name}</div>
        <div className="pane-caption">{caption}</div>
      </div>
    </div>
  </div>

export default Pane
