import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Picture from 'gatsby-image'

export default props =>
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "books-1000x1000.png" }) {
          childImageSharp {
            fluid(maxWidth: 450, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data =>
      <Picture fluid={data.image.childImageSharp.fluid} {...props} />
    }
  />
