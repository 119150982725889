export const convertWholeDollarsToCents = dollars =>
  dollars * 100

export const convertCentsToWholeDollars = cents =>
  cents / 100

export const withinRange = (min, max, valueAsNumber) =>
  (typeof min === "undefined" || min === "" || valueAsNumber >= min) &&
  (typeof max === "undefined" || max === "" || valueAsNumber <= max)

export const round = (value, decimals) =>
  Number(Math.round(value + "e" + decimals) + "e-" + decimals)

export const formatCurrency = amount => {
  if (typeof amount !== "number") {
    return amount
  }

  // fix decimal places if there are any cents remaining e.g. "5.60"
  if (amount - Math.floor(amount) > 0) {
    return amount.toFixed(2)
  }

  // otherwise amount it a whole figure, return as is e.g. "18"
  return amount
}
