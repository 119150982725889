import React from 'react'

import './billboard.scss'

import PicSwirl from '../images/swirl'
import PicBooks from '../images/books'
import snail from '../../images/favicon-snail.png'

import Checkout from './checkout'

export default () =>
  <section id="adventure-jock" className="container-fluid px-0 Billboard">

    <PicSwirl className="Billboard-background" style={{
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      zIndex: 1,
    }} />

    <div className="Billboard-content">
      <div className="container">
        <div className="row align-items-center">

          <div className="col-12 col-md-6 order-last order-md-first">
            <h1 className="ADVENTURE-JOCK">Adventure Jock</h1>
            <p className="AND-THE-TREASURE-MAP">and the treasure map</p>

            <p>Jock was lucky enough to find an old treasure map in the backyard.
            He has decided to go on a treasure hunt to see what he can find.
            Will he find treasure? Who will he meet along the way?</p>

            <p>Check out a <a href="#sneak-peek">sneak peek</a> of the book.</p>

            {/*
            <div className="Billboard-cta p-3 my-2" >
              <h4 className="Billboard-buyNow text-uppercase">Torbay School Special - Only $10</h4>
              <p>To celebrate Book Week you can now buy my book for just $10. Contact me at <a href="mailto:rinabotha@gmail.com">rinabotha@gmail.com</a> and I can arrange delivery at school (pay cash or via online banking).</p>
            </div>
            */}

            <div className="Billboard-cta p-3 mt-2">
              <Checkout imageUri={snail} />
            </div>

            <p className="text-right">… also available as an <a href="https://www.amazon.com/Adventure-Jock-treasure-Rina-Botha-ebook/dp/B07PG5P2R3" title="Ebook : Amazon Kindle : Adventure Jock and the treasure map">Amazon Kindle</a> ebook.</p>
          </div>

          <div className="col-12 col-md-6">
            <PicBooks />
          </div>

        </div>
      </div>
    </div>

  </section>
