import React, { Component } from 'react'
import Pane from './pane'

import './panel.scss'

class Panel extends Component {
  state = {
    selectedIndex: 0,
  }

  constructor() {
    super()
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(event, selectedIndex) {
    this.setState({
      selectedIndex
    })
  }

  render() {
    const { selectedIndex } = this.state
    const { panels } = this.props

    return <div className="peek-panel--container">
      <div className="peek-panel--options">
        { panels.map(
            (panel, index) =>
              <Pane
                {...panel}
                index={index}
                active={selectedIndex === index}
                onClick={this.handleClick}
              />
        ) }
      </div>
    </div>
  }
}

export default Panel
