export default {
  // can be empty string or one of the destination keys below
  defaultDestinationKey: "anz",

  // all options for destination freight
  destinations: [
    { key: "anz", label: "Australia or New Zealand", fee: 5.60, maxFee: 15.00 },
    { key: "worldwide", label: "Anywhere else", fee: 6.40, maxFee: 15.00 },
  ]
}
