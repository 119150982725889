import React, { Fragment } from 'react'

const Thanks = ({
    book,
    charge,
    onCloseClick,
}) => {
  if (!book || !charge) {
    return null
  }

  return <Fragment>
    <button type="button" className="close" aria-label="Close" onClick={onCloseClick}>
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 className="Billboard-buyNow">
      <i className="fa fa-check-square" /> THANK YOU!
    </h4>
    <p>Your purchase is complete.</p>
    <p>We've received your order and an email receipt is on its way. We'll let you
    know when your order is dispatched.</p>
    <p>I really hope you enjoy my book!</p>
  </Fragment>
}

export default Thanks
