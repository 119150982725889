import React from 'react'

export default ({
  badgeClass,
  badgeIcon,
  title,
  time,
  inverted,
  children,
}) =>
  <li className={inverted ? "timeline-inverted" : undefined}>
    <div className={"timeline-badge " + badgeClass}>
      <i className={badgeIcon} />
    </div>
    <div className="timeline-panel">
      <div className="timeline-heading">
        <h4 className="timeline-title">{title}</h4>
        { time &&
          <p>
            <small className="text-muted"><i className="fa fa-calendar" /> {time}</small>
          </p>
        }
      </div>
      <div className="timeline-body">
        {children}
      </div>
    </div>
  </li>
