import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Picture from 'gatsby-image'

export default props =>
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "thejourney/model.jpg" }) {
          childImageSharp {
            fixed(width: 200, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data =>
      <Picture fixed={data.image.childImageSharp.fixed} {...props} />
    }
  />
