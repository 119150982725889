import React from 'react'
import Panel from './panel'
import { panels } from './config'

import './index.scss'

export default () =>
  <section id="sneak-peek" className="SneakPeek p-0">

    <div className="container-fluid p-0 py-1 SneakPeek-content">
      <div className="container">
        <div className="my-5">
            <div className="row align-items-center">
              <div className="col-md-1 text-center text-md-left">
                  <h2>A Sneak Peek</h2>
              </div>
              <div className="col-md-11 py-5">
                <Panel panels={panels} />
              </div>
            </div>
        </div>
      </div>
    </div>

  </section>
